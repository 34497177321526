<template>
    <div class="navbar-container d-flex content align-items-center">

        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link
                    class="nav-link"
                    @click="toggleVerticalMenuActive"
                >
                    <b-img
                        v-if="isDark"
                        :src="appLogoCollapsedLight"
                        alt="logo"
                        width="40"
                        height="40"
                    />
                    <b-img
                        v-if="!isDark"
                        :src="appLogoCollapsed"
                        alt="logo"
                        width="40"
                        height="40"
                    />
                </b-link>
            </li>
        </ul>

        <!-- Left Col -->

        <b-navbar-nav class="nav align-items-center ml-auto">
            <customizer class="d-none d-lg-block" />
            <dark-toggler />
            <project-dropdown />
            <user-dropdown type="header" />
        </b-navbar-nav>
    </div>
</template>

<script>
import {
    BLink, BNavbarNav, BImg,
} from 'bootstrap-vue'
import Customizer from '../../../../components/Misc/Customizer.vue'
import DarkToggler from './components/DarkToggler.vue'
import UserDropdown from './components/UserDropdown.vue'
import ProjectDropdown from './components/ProjectDropdown.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { $themeConfig } from '@themeConfig'

export default {
    components: {
        BLink,
        BImg,
        // Navbar Components
        BNavbarNav,

        DarkToggler,

        UserDropdown,
        ProjectDropdown,
        Customizer,
    },
    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {
            },
        },
    },
    setup() {
        // App Name
        const { skin } = useAppConfig()
        const isDark = computed(() => skin.value === 'dark')
        const { appName, appLogoCollapsed, appLogoCollapsedLight } = $themeConfig.app
        return {
            appName,
            appLogoCollapsed,
            appLogoCollapsedLight,
            isDark,
        }
    },
}
</script>
